"use client";

import { useEffect } from "react";
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "@/utils/cn";
export const TextGenerateEffect = ({
  words,
  className
}: {
  words: string;
  className?: string;
}) => {
  const [scope, animate] = useAnimate();
  let wordsArray = words.split(" ");
  useEffect(() => {
    animate("span", {
      opacity: 1
    }, {
      duration: 2,
      delay: stagger(0.2)
    });
  }, [animate]);
  const renderWords = () => {
    return <motion.div ref={scope} data-sentry-element="unknown" data-sentry-component="renderWords" data-sentry-source-file="text-generate-effect.tsx">
        {wordsArray.map((word, idx) => {
        return <motion.span key={word + idx} className={`${idx > 1 ? 'text-cyan-800 dark:text-purple' : 'dark:text-white text-gray-900'} opacity-0`}>
              {word}{" "}
            </motion.span>;
      })}
      </motion.div>;
  };
  return <div className={cn("font-bold", className)} data-sentry-component="TextGenerateEffect" data-sentry-source-file="text-generate-effect.tsx">
      <div className="my-4">
        <div className=" dark:text-white text-black leading-snug tracking-wide">
          {renderWords()}
        </div>
      </div>
    </div>;
};
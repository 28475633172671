"use client";

import Image from "next/image";
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "@/hooks/use-outside-click";
import { experience } from "@/data/index";
export function ExperienceList() {
  const [active, setActive] = useState<(typeof experience)[number] | boolean | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const id = useId();
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") {
        setActive(false);
      }
    }
    if (active && typeof active === "object") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [active]);
  useOutsideClick(ref, () => setActive(null));
  return <>
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="ExperienceList.tsx">
        {active && typeof active === "object" && <motion.div initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} exit={{
        opacity: 0
      }} className="fixed inset-0 bg-black/20 h-full w-full z-10" />}
      </AnimatePresence>
      <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="ExperienceList.tsx">
        {active && typeof active === "object" ? <div className="fixed inset-0  grid place-items-center z-[100]">
            <motion.button key={`button-${active.title}-${id}`} layout initial={{
          opacity: 0
        }} animate={{
          opacity: 1
        }} exit={{
          opacity: 0,
          transition: {
            duration: 0.05
          }
        }} className="flex absolute top-2 right-2 lg:hidden items-center justify-center bg-white rounded-full h-6 w-6" onClick={() => setActive(null)}>
              <CloseIcon />
            </motion.button>
            <motion.div layoutId={`card-${active.title}-${id}`} ref={ref} className="w-full max-w-[500px]  h-fit md:max-h-[90%]  flex flex-col bg-white dark:bg-neutral-900 sm:rounded-3xl overflow-hidden">

              <div>
                <div className="flex justify-between items-start p-4 mt-2">
                  <div className="">
                    <motion.h3 layoutId={`title-${active.title}-${id}`} className="font-bold text-neutral-700 dark:text-neutral-200">
                      {active.title}
                    </motion.h3>
                    <motion.p layoutId={`description-${active.description}-${id}`} className="text-neutral-600 dark:text-neutral-400">
                      {active.description}
                    </motion.p>
                  </div>

                  <motion.p layoutId={`date-${active.title}-${id}`} className="px-4 py-3 text-sm font-bold text-neutral-700 dark:text-white">
                    {active.date}
                  </motion.p>
                </div>
                <div className="pt-4 relative px-4">
                  <motion.div layout initial={{
                opacity: 0
              }} animate={{
                opacity: 1
              }} exit={{
                opacity: 0
              }} className="text-neutral-600 text-xs md:text-sm lg:text-base h-40 md:h-fit pb-6 flex flex-col items-start gap-4 overflow-auto dark:text-neutral-400 [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]">
                    {typeof active.content === "function" ? active.content() : active.content}
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div> : null}
      </AnimatePresence>
      <ul className="max-w-2xl mx-auto w-full gap-4">
        {experience.map((card, index) => <motion.div layoutId={`card-${card.title}-${id}`} key={`card-${card.title}-${id}`} onClick={() => setActive(card)} className="p-4 flex flex-col md:flex-row justify-between items-center hover:bg-neutral-200 dark:hover:bg-neutral-800 rounded-xl cursor-pointer">
            <div className="flex gap-4 flex-col md:flex-row ">
              <div className="">
                <motion.h3 layoutId={`title-${card.title}-${id}`} className="font-medium text-neutral-800 dark:text-neutral-200 text-center md:text-left">
                  {card.title}
                </motion.h3>
                <motion.p layoutId={`description-${card.description}-${id}`} className="text-neutral-600 dark:text-neutral-400 text-center md:text-left">
                  {card.description}
                </motion.p>
              </div>
            </div>
            <motion.p layoutId={`date-${card.title}-${id}`} className="px-4 py-2 text-sm font-bold text-neutral-700 dark:text-white mt-4 md:mt-0">
              {card.date}
            </motion.p>
          </motion.div>)}
      </ul>
    </>;
}
export default ExperienceList;
export const CloseIcon = () => {
  return <motion.svg initial={{
    opacity: 0
  }} animate={{
    opacity: 1
  }} exit={{
    opacity: 0,
    transition: {
      duration: 0.05
    }
  }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4 text-black" data-sentry-element="unknown" data-sentry-component="CloseIcon" data-sentry-source-file="ExperienceList.tsx">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" data-sentry-element="path" data-sentry-source-file="ExperienceList.tsx" />
      <path d="M18 6l-12 12" data-sentry-element="path" data-sentry-source-file="ExperienceList.tsx" />
      <path d="M6 6l12 12" data-sentry-element="path" data-sentry-source-file="ExperienceList.tsx" />
    </motion.svg>;
};
"use client";

import { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import { Switch } from "@nextui-org/react";
import { SunIcon } from "./ui/SunIcon";
import { MoonIcon } from "./ui/MoonIcon";
const ThemeSwitcher = () => {
  const [mounted, setMounted] = useState(false);
  const {
    theme,
    setTheme
  } = useTheme();

  // Ensure the component is mounted before rendering to avoid hydration mismatch issues
  useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  const handleThemeChange = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };
  return <Switch defaultSelected={theme === "light"} size="lg" color="warning" onChange={handleThemeChange} thumbIcon={({
    isSelected,
    className
  }) => isSelected ? <SunIcon className={className} /> : <MoonIcon className={className} />} data-sentry-element="Switch" data-sentry-component="ThemeSwitcher" data-sentry-source-file="ThemeSwitcher.tsx" />;
};
export default ThemeSwitcher;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ExperienceList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/ThemeSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AnimatedTooltip"] */ "/vercel/path0/components/ui/animated-tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingNav"] */ "/vercel/path0/components/ui/floating-navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextGenerateEffect"] */ "/vercel/path0/components/ui/text-generate-effect.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
